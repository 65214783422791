(function ($, Drupal) {
  Drupal.behaviors.smThMain = {
    attach: function (context, settings) {
      /////////////////

      // Add class in Paginator
      $( "ul.pager .pager-item" ).first().addClass( "firstNormalItem" );

      // Animate scroll to top on footer block.
      $(".block-sm-custom-blocks .go-top").click(function (e) {
        e.preventDefault();
        window.scroll({top: 0, left: 0, behavior: "smooth"});
      });

      // Add site pathname for each link inside of admindpo text.
      $('#admindpo').parent().parent().one('DOMSubtreeModified', function() {
        $(this).find('a').each(function() {
          var link = $(this)[0]
          var site = $(location).attr('pathname').split('/')[1]

          if (!link.pathname.includes('/' + site)) {
            link.pathname = '/' + site + link.pathname
          }
        })
      })


      /////////////////
    }
  };

})(jQuery, Drupal);
